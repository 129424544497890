import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import HomeSlider from "./Home/HomeSlider";
import BestOffers from "./Home/BestOffers";
import MonthelyEssential from "./Home/MonthelyEssential";
import HomeCategory from "./Home/HomeCategory";
import HotProducts from "./Home/HotProducts";
import InfocousToday from "./Home/InfocousToday";
import AllCategories from "./Home/AllCategories";
import FeatureesThisweek from "./Home/FeatureesThisweek";
import TodayFeature from "./Home/TodayFeature";
import HotDeals from "./Home/HotDeals";
import NonVegCategories from "./Home/NonVegCategories";
import SupermartCategories from "./Home/SupermartCategories";
import CommingSoon from "./CommingSoon";
import RecommandedProducts from "./Home/RecommandedProducts";
import TrendingProducts from "./Home/TrendingProducts";
import OfferProducts from "./Home/OfferProducts";
import DryfruitsoilsandmasalasSubCats from "./Home/DryfruitsoilsandmasalasSubCats";
import DryFruifsSubcat from "./Home/subcategory/DryFruifsSubcat";
import RiceDalSubcat from "./Home/subcategory/RiceDalSubcat";
import Dairyandbread from "./Home/subcategory/Dairyandbread";
import InstantfoodSubcat from "./Home/subcategory/InstantfoodSubcat";
import ChocolatesdessertsSubcat from "./Home/subcategory/ChocolatesdessertsSubcat";
import MunchiesSubcat from "./Home/subcategory/MunchiesSubcat";
import ColddrinksandjuicesSubcat from "./Home/subcategory/ColddrinksandjuicesSubcat";
import CerealsandbreakfastSubcat from "./Home/subcategory/CerealsandbreakfastSubcat";
import SaucesandspreadsSubcat from "./Home/subcategory/SaucesandspreadsSubcat";
import TeacoffeeandmoreSubcat from "./Home/subcategory/TeacoffeeandmoreSubcat";
import BiscuitsSubcat from "./Home/subcategory/BiscuitsSubcat";
import BathbodyandhairSubcat from "./Home/subcategory/BathbodyandhairSubcat";
import Cleaningessentials from "./Home/subcategory/Cleaningessentials";
import Beautyandgrooming from "./Home/subcategory/Beautyandgrooming";
import Homeandkitchenneeds from "./Home/subcategory/Homeandkitchenneeds";
import Pharmaandhygiene from "./Home/subcategory/Pharmaandhygiene";
import CategoriesAll from "./Home/CategoriesAll";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { useSelector } from "react-redux";

const Home = () => {
  const { showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );
  const [visible, setVisible] = useState(false);

  return (
    <>
      <HomeSlider />
      <HotProducts />
      {/* <MonthelyEssential /> */}

      <TrendingProducts />
      <CategoriesAll />
      {/* <HomeCategory /> */}
      {/* <NonVegCategories /> */}
      {/* <SupermartCategories /> */}

      <DryFruifsSubcat />
      <RiceDalSubcat />
      <Dairyandbread />
      <InstantfoodSubcat />

      <RecommandedProducts />

      <ChocolatesdessertsSubcat />
      <MunchiesSubcat />
      <ColddrinksandjuicesSubcat />
      <CerealsandbreakfastSubcat />
      <SaucesandspreadsSubcat />

      <OfferProducts />

      <TeacoffeeandmoreSubcat />
      <BiscuitsSubcat />
      <BathbodyandhairSubcat />
      <Cleaningessentials />

      {/* <TrendingProducts /> */}

      <Beautyandgrooming />
      <Homeandkitchenneeds />
      <Pharmaandhygiene />

      {/* <DryfruitsoilsandmasalasSubCats /> */}

      {/* <AllCategories /> */}
      {/* <BestOffers />
      <InfocousToday />
      <FeatureesThisweek /> */}
      {/* <HotDeals /> */}
      {/* <TodayFeature /> */}
    </>
  );
};

export default Home;
