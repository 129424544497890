import React, { useEffect, useState, useRef} from "react";
import axios from "axios";
import { BsHouseDoor } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  LoginOtp,
  loginAuthentication,
  pageStatusChange
} from "../../redux/athentication/Athentication";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { BiCategoryAlt } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { FaRegHeart, FaUserCircle } from "react-icons/fa";
import { TfiLocationPin } from "react-icons/tfi";
import { FiSearch, FiUser } from "react-icons/fi";
import { BsTelephone, BsCart3 } from "react-icons/bs";
import {
  getCartTotal,
  removefromCart,
  setSearchProducts,
} from "../../redux/cart/CartSlice";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSerch] = useState("");
  const [subCatSerchList, setSubCatSerchList] = useState("");
  const [catSerchList, setCatSerchList] = useState([]);
  const [prodSerchList, setProdSerchList] = useState([]);
  const [brandprodSerchList, setBrandProdSerchList] = useState("");
  const [showResults, setShowResults] = useState(false);
  const ref = useRef();

  const [fetchAllProducts, setFetchAllProducts] = useState([]);
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { categoryTotal } = useSelector((state) => state.Category);
  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );
  const { isAuth, name, loginData, showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );

  const [number, setNumber] = useState("");
  const [errormassage, setErrormassage] = useState("");

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setNumber(numbers);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      if (number === "8328059954") {
        OTP = "111444";

      }
      dispatch(LoginOtp({ number, OTP }));

      const formdata = {
        number: number,
        OTP: OTP,
      };
      dispatch(loginAuthentication(formdata));

      // async function fetchData() {
      //   const res = await fetch(
      //     `http://smscube.in/api/mt/SendSMS?APIKey=sHlFlyxFy0GpOWKmWHnT4A&senderid=BMAXMS&channel=2&DCS=0&flashsms=0&number=${number}&text=Your%20OTP%20for%20logging%20in%20to%20Salesbees%20account%20is%20${OTP}.%20Do%20not%20share%20with%20anyone.%20%E2%80%93%20BMAX%20Marketing%20Solutions.&route`
      //   );
      //   res
      //     .json()
      //     .then((res) => res)
      //     .catch((err) => err);
      // }
      // fetchData();
    } else {
      setErrormassage(" Please enter valid number");
    }
  };
  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);
  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(pageStatusChange(0));
  }, [CartItems, dispatch]);
  const removefromcarts = (cartItem) => {
    dispatch(removefromCart(cartItem));
  };
  const searchchange = (e) => {
    const result = e.target.value;
    setSerch(result);

    if (result.length >= 2) {
      const filtersubcatbyserch = subCategoryTotal
        .filter((subcat) =>
          subcat.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 2);
      const filtercatdbyserch = categoryTotal
        .filter((cat) => cat.name.toLowerCase().match(result.toLowerCase()))
        .slice(0, 2);
      const filterProductbyserch = ProductTotal.filter((product) =>
        product.ItemName.toLowerCase().match(result.toLowerCase())
      ).slice(0, 2);

      // const filterBrandbyserch = groceresBrand
      //   .filter((brand) => brand.name.toLowerCase().match(result.toLowerCase()))
      //   .slice(0, 2);
      setSubCatSerchList(filtersubcatbyserch);
      setCatSerchList(filtercatdbyserch);
      setProdSerchList(filterProductbyserch);
      // setBrandProdSerchList(filterBrandbyserch);
      setShowResults(true);
    }
  };
  
  const searchsubmit = (e) => {
    e.preventDefault();
    navigate(`/product-search/${search}`);
    setShowResults(false);
  };

  const serchclick = () => {
    navigate(`/product-search/${search}`);
    setShowResults(false);
  };

  return (
    <>
      <header className="pb-md-3" >
        {/* =============header top start===================== */}
        <div className="top-nav top-header sticky-header" style={{boxShadow:"box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
          <div className="container-fluid-lg">
            <div className="row">
              <div className="col-12">
                <div className="navbar-top">
                  {/* <button
                    className="navbar-toggler d-xl-none d-inline navbar-menu-button"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#primaryMenu"
                  >
                    <span className="navbar-toggler-icon">
                      <i className="fa-solid fa-bars" />
                    </span>
                  </button> */}

                  <a className="web-logo nav-logo">
                    <Link to="/">
                      <img
                        src="../assets/images/logo/Logo KiranaWorld.png"
                        className="img-fluid blur-up lazyload"
                        alt="img"
                      />
                    </Link>
                  </a>

                  {/* ============middle start============== */}
                  <div className="middle-box dextop">
                    <div className="location-box">
                      <button
                        className="btn location-button"
                        data-bs-toggle="modal"
                        data-bs-target="#locationModal"
                      >
                        <span className="location-arrow">
                          <TfiLocationPin />
                        </span>
                        <span className="locat-name">Your Location</span>
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </div>
                    <div className="search-box" ref={ref}>
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="I'm searching for..."
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            onChange={searchchange}
                          />
                          <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                          >
                            <FiSearch />
                          </button>
                        </div>
                      </form>
                      {showResults && (
                        <>
                          <div className="serchsugist">
                            {/* <ul>
                              {subCatSerchList &&
                                subCatSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6>in {data.category} Category</h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul> */}
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6>In Product</h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6> {data.name} in Brand</h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* ============middle end============== */}

                  <div className="rightside-box">
                    <div className="search-full">
                      <div className="input-group">
                        <span className="input-group-text">
                          <FiSearch />
                        </span>
                        <input
                          type="text"
                          className="form-control search-type"
                          placeholder="Search here.."
                        />
                        <span className="input-group-text close-search">
                          <i data-feather="x" className="font-light" />
                        </span>
                      </div>
                    </div>
                    <ul className="right-side-menu">
                      <li className="right-side">
                        <div className="delivery-login-box">
                          <div className="delivery-icon">
                            <div className="search-box">
                              <i data-feather="search" />
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="right-side">
                        <a className="delivery-login-box">
                          <Link to="/HotProductDetails">
                            <div className="delivery-icon" >
                              <BsTelephone />
                            </div>
                          </Link>
                          {/* <div className="delivery-detail">
                            <h6>24/7 Delivery</h6>
                            <h5>+91 888 104 2340</h5>
                          </div> */}
                        </a>
                      </li>
                      {/* <li className="right-side">
                        <Link to="wishlist">
                          <a className="btn p-0 position-relative header-wishlist">
                            <FaRegHeart />
                          </a>
                        </Link>
                      </li> */}
                      <li className="right-side">
                        <div className="onhover-dropdown header-badge">
                          <Link to="/cart">
                            {CartItems.length === 0 ? (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3 />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3 />
                                <span className="position-absolute top-0 start-100 translate-middle badge">
                                  {CartItems.length}
                                </span>
                              </button>
                            )}
                          </Link>
                          {CartItems.length === 0 ? (
                            <></>
                          ) : (
                            <div className="onhover-div">
                              <ul className="cart-list">
                                {CartItems.map((data, index) => (
                                  <li
                                    className="product-box-contain"
                                    key={index}
                                  >
                                    <div className="drop-cart">
                                      <a className="drop-image">
                                        <img
                                          src={data.ImgUrl}
                                          className="blur-up lazyload"
                                          alt="img"
                                        />
                                      </a>
                                      <div className="drop-contain">
                                        <a>
                                          <h5>{data.ItemName}</h5>
                                        </a>
                                        <h6>
                                          <span>{data.Qty} x</span> ₹
                                          {data.Price}
                                        </h6>
                                        <button
                                          className="close-button close_button"
                                          onClick={() => removefromcarts(data)}
                                        >
                                          <GrFormClose />
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <div className="price-box">
                                <h5>Total :</h5>
                                <h4 className="theme-color fw-bold">
                                  ₹{cartTotalAmount}
                                </h4>
                              </div>
                              <div className="button-group">
                                <Link to="/cart">
                                  <button className="btn btn-sm cart-button">
                                    View Cart
                                  </button>
                                </Link>

                                {isAuth ? (
                                  <>
                                    <Link to="/checkout">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to="/login">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                      {/* <li className="right-side onhover-dropdown">
                        <div className="delivery-login-box">
                          {isAuth ? (
                            <Link to="/account">
                              <div className="delivery-icon">
                                <FaRegUserCircle
                                  style={{ fontSize: "32px" }}
                                  className="mx-2"
                                />{" "}
                                {name}
                              </div>
                            </Link>
                          ) : (
                            <Link to="/login" >
                              <div className="delivery-icon loginserch" >
                                <FaRegUserCircle
                                  style={{ fontSize: "32px" }}
                                  className="mx-2"
                                />
                               
                              </div>
                            </Link>
                          )}
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>

                {showMassage === true ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "5vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#0e947a",
                        borderRadius: "3px",
                      }}
                     
                    >
                      <marquee scrolldelay="100">
                        <h6
                          style={{
                            fontSize: "1.1rem",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {massageText}
                        </h6>
                      </marquee>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="navbar-top ">
                  <div className="middle-box mobile mt-2 ">
                    {/* <div className="location-box">
                      <button
                        className="btn location-button"
                        data-bs-toggle="modal"
                        data-bs-target="#locationModal"
                      >
                        <span className="location-arrow">
                          <TfiLocationPin />
                        </span>
                        <span className="locat-name">Your Location</span>
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </div> */}
                    {/* <div className="search-box" ref={ref} >
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group" >
                      
                          <input
                            type="search"
                            className="form-control"
                            placeholder="I'm searching for..."
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            ref={ref}
                            onChange={searchchange}
                          />
                           <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                          >
                            <FiSearch />
                          </button>
                        </div>
                      </form>
                      {showResults && (
                        <>
                          <div className="serchsugist ">
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => setShowResults(false)}
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6>In Product</h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => setShowResults(false)}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => setShowResults(false)}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6> {data.name} in Brand</h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============header top end===================== */}
      </header>

      <section className="log-in-section background-image-2 section-b-space">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="../assets/images/inner-page/log-in.png"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
              <div className="log-in-box">
                {/* <div style={{ textAlign: "center" }}>
                  <img
                    src="../assets/images/logo/Logo KiranaWorld.png"
                    alt="img"
                    height="80px"
                    width="170px"
                  />
                </div> */}
                <div className="login-image">
                <img
                  src="../assets/images/inner-page/log-in.png"
                    height='120px'
                    width='190px'
                  alt="img"
                />
              </div>
                <div className="log-in-title">
                  <h3>Welcome To KiranaWorld</h3>
                  <h4>Log in or Sign up</h4>
                </div>
                <div className="input-box">
                  <form className="row g-4" onSubmit={onSubmitHandler}>
                    <div className="col-12">
                      {/* <div className="form-floating theme-form-floating log-in-form">
                        <input
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="Enter Mobile Number"
                          value={number}
                          minLength={10}
                          maxLength={10}
                          pattern="[6789][0-9]{9}"
                          onChange={(e) => handleChange(e)}
                        />
                        <p style={{ color: "tomato" }}>{errormassage}</p>
                        <label htmlFor="text">Enter Mobile Number</label>
                      </div> */}

                      <div className="textfieldcontainer">
                        <TextField
                          label="Enter Mobile Number"
                          // variant="outlined"
                          className="textfield"
                          type="text"
                          id="text"
                          
                          value={number}
                          minLength={10}
                          maxLength={10}
                          pattern="[6789][0-9]{9}"
                          onChange={(e) => handleChange(e)}
                          inputProps={{ style: { borderColor: "red" } }}
                        />
                        <p style={{ color: "tomato" }}>{errormassage}</p>
                      </div>
                      {/* <div className="textfieldcontainer">
                        <TextField id="standard-basic"
                          label="Enter Mobile Number"
                          variant="standard"
                          className="textfield"
                          type="text"
                         
                          value={number}
                          minLength={10}
                          maxLength={10}
                          pattern="[6789][0-9]{9}"
                          onChange={(e) => handleChange(e)}
                          inputProps={{ style: { borderColor: "red" } }}

                        />
                      </div> */}
                    </div>
                    <div
                      className="col-12 bg-danger"
                      style={{ borderRadius: "10px" }}
                    >
                      <button
                        className="btn btn-animation w-100 justify-content-center"
                        type="submit"
                        style={{ height: "50px", fontSize: "16px" }}
                      >
                        Continue
                      </button>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <p style={{ textAlign: "center" }}>
                        By continuing, you agree to KiranaWorld{" "}
                        <b style={{ color: "#0e947a" }}> Terms of Use </b>and{" "}
                        <b style={{ color: "#0e947a" }}>Privacy Policy</b>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mobile-menu d-md-none d-block mobile-cart">
        <ul>
          <li className="active">
            <Link to="/">
              <a>
                {/* <IoHomeOutline className=" icli" /> */}
                <Link to="/">
                      <img
                        src="../assets/images/logo/Logo KiranaWorld.png"
                    alt="img"
                    height="22px"
                    width="45px"
                      />
                    </Link>
                <span>Home</span>
              </a>
            </Link>
          </li>
          <li className="mobile-category">
            <Link to="/">
              <a>
                <BiCategoryAlt className="iconly-Category icli js-link" />
                <span>Category</span>
              </a>
            </Link>
          </li>
          {/* <li>
            <Link to="/">
              <a className="search-box">
                <FiSearch className="iconly-Search icli" />
                <span>Search</span>
              </a>
            </Link>
          </li> */}
          <li>
            <Link to="/">
              <a className="notifi-wishlist">
                <FaRegHeart className="iconly-Heart icli" />
                <span>My Wish</span>
              </a>
            </Link>
          </li>
          <li>
            <Link to="/cart">
              <div className="onhover-dropdown header-badge">
                <a className="position-relative header-wishlist">
                  <BsCart3 className="iconly-Bag-2 icli fly-cate" />
                  <span>Cart</span>
                  <span className="position-absolute top-0 start-100 translate-middle badge bg-light text-danger">
                    {CartItems.length}
                  </span>
                </a>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/login">
              <a className="notifi-wishlist">
                <FaRegUserCircle className="iconly-Heart icli" />
                <span>Account</span>
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LoginScreen;
